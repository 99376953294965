import { useState, useEffect } from 'react';
import type {
    UserFavouritedItem,
    FavouriteItem,
} from '../type/design-board-types';
import { isFavouritedItem } from '../util/design-board-utils';
import { USER_FAVOURITED_ITEM_STORAGE_KEY } from '../config/design-board-config';
import type { SaveToDesignBoardProps } from '~/modules/design-board/component/SaveToDesignBoard';
import { localStorage } from '~/modules/root/util/browser-storage';

export const useSaveToDesignBoard = (
    itemType: SaveToDesignBoardProps['itemType'],
    itemId?: SaveToDesignBoardProps['itemId'],
    urlSearchParams?: string
) => {
    const [popupTarget, setPopupTarget] = useState<HTMLElement>();
    const [favouriteItemId, setFavouriteItemId] = useState<number>(itemId || 0);
    const [libraryItemId, setLibraryItemId] = useState<number>(itemId || 0);
    const [clickToken, setClickToken] = useState<number>();
    const [favouritedItems, setFavouritedItems] = useState<
        UserFavouritedItem[]
    >([]);
    const [isFavourited, setIsFavourited] = useState<boolean>(false);

    const onFavouriteClick = (
        e: React.SyntheticEvent<HTMLElement>,
        itemId: number,
        libraryItemIdOverride?: number
    ) => {
        e.stopPropagation();
        e.preventDefault();
        setPopupTarget(e.currentTarget as HTMLElement);
        setFavouriteItemId(itemId);
        setLibraryItemId(libraryItemIdOverride ?? itemId);

        setClickToken(Math.random());
    };
    const onFavouriteSave = (favouritedItem: UserFavouritedItem) => {
        setFavouritedItems([...favouritedItems, favouritedItem]);
        setIsFavourited(true);
    };

    const favourited = (itemId?: number) => {
        if (!itemId) {
            return false;
        }
        return isFavouritedItem(itemId, itemType, favouritedItems, undefined);
    };

    useEffect(() => {
        const handleLocalStorageChange = () => {
            const item = JSON.parse(
                localStorage?.getItem(USER_FAVOURITED_ITEM_STORAGE_KEY) || '[]'
            );

            setFavouritedItems((currentItem) => {
                const currentIsEmpty = currentItem.length === 0;
                const newIsEmpty = item.length === 0;
                const shouldUpdate = !(currentIsEmpty && newIsEmpty);

                // React runs a refrential equality check which fails on empty arrays
                if (item && shouldUpdate) {
                    return item;
                }

                return currentItem;
            });
        };

        handleLocalStorageChange();
        window.addEventListener('storage', handleLocalStorageChange);

        return () => {
            window.removeEventListener('storage', handleLocalStorageChange);
        };
    }, []);

    useEffect(() => {
        if (favouriteItemId) {
            setIsFavourited(
                isFavouritedItem(favouriteItemId, itemType, favouritedItems)
            );
        }
    }, [favouriteItemId, favouritedItems, itemType]);

    useEffect(() => {
        setFavouriteItemId(itemId || 0);
    }, [itemId, setFavouriteItemId]);

    const favouriteItem: FavouriteItem = {
        itemId: favouriteItemId,
        itemType: itemType,
        target: popupTarget,
        clickToken: clickToken,
        isFavourited: isFavourited,
        onSave: onFavouriteSave,
        onClick: onFavouriteClick,
        favourited: favourited,
        urlSearchParams,
        libraryItemIdOverride: libraryItemId,
    };

    return {
        favouriteItem,
        favouritedItems,
    };
};
